@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Young+Serif&display=swap");

/* font-family: "Instrument Serif", serif;
font-family: "Young Serif", serif; */

.container-fluid.get-in-touch {
  padding: 5rem;
}

.get-in-touch .row.justify-content-center .form-title {
  font-family: "Young Serif", serif !important;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.get-in-touch .row.justify-content-center .form-description {
  font-size: 1rem;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 1rem auto;
}

.get-in-touch .row.justify-content-center .btn {
  display: block;
  margin: 1rem auto;
}

.get-in-touch .card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.get-in-touch .card {
  width: 100%;
  max-width: 23rem;
  margin: 2rem 0.5rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.get-in-touch .card .card_body {
  background-color: #f9f9f9;
  padding: 1rem 1.6rem;
}

.get-in-touch .card .card_body .card-title span {
  font-size: 12px;
  font-weight: 400;
}

.get-in-touch .card .card_body .d-flex span {
  font-size: 18px;
  font-weight: 700;
}

.get-in-touch .card .card_body .my-3 {
  border-bottom: 2px solid #d7d7d7;
}

.get-in-touch .card .card_body .card-text {
  font-size: 15px;
  font-weight: 400;
  color: #121212;
}

.get-in-touch .input-styles {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  background-color: white; /* Added background color */
}

.get-in-touch .input-styles input,
.get-in-touch .input-styles textarea {
  background-color: white; /* Added background color */
  border: none;
  outline: none;
  flex-grow: 1;
}

.get-in-touch .input-styles .icon {
  margin-right: 0.17rem;
  color: #6c757d;
}

.get-in-touch .error-message {
  color: orangered;
  font-size: 10px;
}

.get-in-touch button {
  width: 100%;
  font-family: "Young Serif", serif !important;
}
