.location_slide_wrap .swiper {
  overflow: hidden;
  width: 100%; /* Adjust based on your layout */
  /* position: relative !important; */
}

.location_slide_wrap .swiper-slides {
  display: flex;
  transition: transform 0.3s ease-in-out;
  gap: 1.1rem;
}

.location_slide_wrap .slide {
  /* width: 25%; */
  flex-shrink: 0;
  transition: opacity 0.3s ease;
}

.location_slide_wrap .slide.active {
  opacity: 1;
}
/*  */

.location_wrap {
  width: 280px;
  height: 376px;
  overflow: hidden;
}

.location_wrap img {
  height: 100%;
  border-radius: 16px;
}

@media (max-width: 460px) {
  .location_slide_wrap {
    margin-left: -3px;
  }
  .location_slide_wrap .swiper-slides {
    gap: 0.7rem;
  }
  .location_wrap {
    width: 252px;
    height: 355px;
  }
}
