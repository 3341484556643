.c-container {
  justify-content: space-around;
  gap: 1rem;
}
.c-container > img {
  width: 9rem;
}

@media (max-width: 640px) {
  .paddings {
    padding: 15px 5px !important;
  }
  .paddings .comp_img {
    width: 35% !important;
  }
  .paddings span {
    font-size: 0.7rem;
  }
}
