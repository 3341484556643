.body {
  position: relative;
}

.App {
  position: relative;
  overflow-x: clip;
  background: linear-gradient(to bottom, #121212 72%, #121212 72%);
  /* background-position: center; */
}
.App--prop {
  position: relative;
  overflow-x: clip;
  min-height: 750px;
  background-image: linear-gradient(to bottom, #000000ad 30%, #000000ad 80%),
    url("/public/props-img-page.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.App--prop-reqed {
  position: relative;
  overflow-x: clip;
  min-height: 60vh;
  background-image: linear-gradient(to bottom, #2422229f 30%, #1d1c1c8a 80%),
    url("/public/property-req.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-position: center;
}

.App--selected-projs {
  position: relative;
  overflow-x: clip;
  min-height: 60vh;
  background-image: linear-gradient(to bottom, #2422229f 30%, #1d1c1c8a 80%),
    url("/public/selected-proj.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.App--selected-blog {
  position: relative;
  overflow-x: clip;
  min-height: 60vh;
  background-image: linear-gradient(to bottom, #000000a6 30%, #000000a6 80%),
    url("/public/selected-blog-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-position: center;
}
.App--about {
  position: relative;
  overflow-x: clip;
  min-height: 60vh;
  background-image: linear-gradient(to bottom, #000000a6 30%, #000000a6 80%),
    url("/public/about_img-5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-position: bottom;
}

/*  */
.App--props-selected {
  position: relative;
  overflow-x: clip;
  min-height: 50vh;
  background: linear-gradient(to bottom, #fff 30%, #fff 80%);
}

/*  */
.App--projs {
  position: relative;
  overflow-x: clip;
  min-height: 60vh;
  background: linear-gradient(to bottom, #000 30%, #000 80%);
}
.App--props-selected > :nth-child(1) {
  color: #000000;
}

.App > :nth-child(1) {
  /* background: #121212; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
}

.hero-wrapper {
  color: white;
  position: relative;
  z-index: 1;
  /* padding-bottom: 2rem; */
}

.hero-container {
  justify-content: space-around;
  align-items: flex-end;
}

/* Modal */

/* linear */
.hero-img-down {
  background-image: linear-gradient(to bottom, #121212 20%, #121212b8 80%),
    url("/public/home-hero-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.hero-img-down .w-100x {
  width: 100% !important;
  height: 412px;
}
.hero-img-down .italic_p {
  font-size: 17px;
  font-weight: 350;
  font-style: italic;
  padding: 0 2px;
  width: 413px;
}

/* linear */

/* left side */
.hero-left {
  gap: 3rem;
}
.hero-title {
  position: relative;
  z-index: 1;
}

.hero-title > h1,
.h1-one {
  font-weight: 600;
  font-size: 3rem;
  line-height: 4rem;
}
.orange-circle {
  height: 4rem;
  width: 4rem;
  background: var(--orange-gradient);
  border-radius: 999px;
  position: absolute;
  right: 28%;
  top: -10%;
  z-index: -1;
}
.search-bar {
  background-color: white;
  border-radius: 5px;
  border: 3px solid rgba(120, 120, 120, 0.374);
  padding: 0.5rem 1rem;
  width: 100%;
  justify-content: space-between;
}
.search-bar > input {
  border: none;
  outline: none;
}
.stats {
  width: 100%;
  justify-content: space-between;
}
.stat > :nth-child(1) {
  font-size: 2rem;
}
.stat > :nth-child(1) > :last-child {
  color: orange;
}
/* right side */

.image-container {
  width: 30rem;
  height: 35rem;
  overflow: hidden;
  border-radius: 15rem 15rem 0 0;
  border: 8px solid rgba(255, 255, 255, 0.12);
}
.image-container > img {
  width: 100%;
  height: 100%;
}

.hero-btn button {
  border-radius: 30px;
  padding: 14px 31px;
  font-size: 15px;
  font-weight: 500;
}

.line-img {
  margin-top: 1rem;
  border-radius: 30px;
  padding: 6px 30px;
}

.line-img svg {
  font-size: 1.6rem;
}

div .line-img:hover {
  color: #fff;
  background-color: transparent !important;
}

.blog_text span {
  font-size: 15px;
  font-weight: 400;
}

/* mobile screens */

/* Responsive styles */

#slide_prop {
  display: none !important;
}

@media (max-width: 1200px) {
  /* Adjust styles for screens up to 1200px */
  .search-bar,
  .stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero-btn button {
    border-radius: 30px;
    padding: 6px 16px;
    /* font-size: 17px; */
  }
}

@media (max-width: 940px) {
  .brk_none {
    display: none;
  }
  /* Adjust styles for screens up to 940px */
  .center-display {
    justify-content: center !important;
  }

  .search-bar,
  .stats {
    flex-direction: column;
    align-items: flex-start;
  }
  .hero-btn button {
    border-radius: 30px;
    padding: 5px 14px;
    /* font-size: 16px; */
  }
}

/* 
 */

@media only screen and (min-width: 768px) {
  .sel_project_wrap-2.dflex_1st12 {
    display: none;
  }
}

/* Hide the second div on mobile */
@media only screen and (max-width: 767px) {
  .display-none12 {
    display: none;
  }
}

/*  */

@media (max-width: 740px) {
  .App--selected-projs {
  }
  .App--selected-projs .sel_project_wrap {
    margin-bottom: 1.8rem !important;
  }
  .App--selected-projs .sel_project_wrap-2 {
    margin-right: -12px !important;
    padding-left: 5px !important;
  }

  .App--props-selected {
    min-height: 20vh;
  }

  .App--props-selected .dflex_1st img {
    width: 100%;
    height: 430px;
  }
  .App--props-selected .dflex_2nd {
    margin-top: 0px !important;
    margin-left: 1.3rem;
  }
  .App--props-selected .dflex_2nd .input-styles {
    width: 70% !important;
  }
  .App--props-selected .dflex_2nd span {
    font-size: 13px;
  }
  .App--props-selected .input-styles {
    width: fit-content;
  }
}

@media (max-width: 640px) {
  #slide_prop {
    display: block !important;

    max-width: 400px;
    overflow-x: hidden;
  }
  /*  */
  .App--props-selected .span_12 {
    font-size: 13px !important;
  }

  .App--projs .p-5 {
    padding: 10px !important;
    /* padding-left: 1.5rem !important; */
  }

  .hero-container {
    margin-top: 2rem;
  }
  .hero-title > h1 {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .image-container {
    width: 95%;
    height: 25rem;
  }
  .stats {
    justify-content: center;
    gap: 1.5rem;
    font-weight: wrap;
  }
  .stat > :nth-child(1) {
    font-size: 1.5rem;
  }
  .stat > :nth-child(2) {
    font-size: 0.8rem;
  }
  /* Adjust styles for screens up to 640px */
  .hero-title > h1,
  .h1-one {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  .search-bar,
  .stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .dflex_1st {
    /* Your styles for the first column on small screens go here */
    order: 1; /* Example: Change the order to 1 */
  }

  .dflex_1st .div_btn {
    display: none !important;
  }

  .dflex_2nd {
    /* Your styles for the second column on small screens go here */
    order: 2; /* Example: Change the order to 2 */
    padding: 30px 10px;
  }
  .dflex_2nd p {
    font-size: 14px;
    text-align: center;
  }

  .dflex_2nd .hero-btn {
    justify-content: center;
  }

  .dflex_2nd .hero-btn .btn {
    font-size: 14px;
    padding: 5px 13px;
  }

  .home_img_wrap {
    width: 180px;
  }
}

@media (max-width: 570px) {
}

@media (max-width: 460px) {
  .App--props-selected .main-selected-img {
    height: 385px !important;
    width: 376px !important;

    /*  */
    /* position: relative; */
  }
  /* Adjust styles for screens up to 480px */
  .hero-title > h1,
  .h1-one {
    font-size: 2rem;
    line-height: 3rem;
  }

  .search-bar,
  .stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .home_img_wrap {
    width: 141px !important;
    height: 100px;
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  /*  */
  .dflex_1st {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .carousel-inner {
    width: 100%;
    height: 349px !important;
  }
  .hero-img-down .w-100x {
    width: 100% !important;
    height: 192px;
  }

  .line-img {
    margin-top: 1rem;
    border-radius: 30px;
    padding: 6px 20px;
  }
  .line-img img {
    width: 26px;
  }

  .less460 span {
    font-size: 8px !important;
  }

  .dflex_2nd {
    align-items: center;
  }

  .hero-img-down .italic_p {
    font-size: 14px;
    max-width: 325px;
  }

  .dflex_2nd .hero-btn {
    flex-direction: column;
    gap: 2rem !important;
    padding: 0 3.5rem;
  }
  .dflex_2nd .hero-btn button {
    padding: 10px 21px !important;
  }
}

@media (max-width: 360px) {
  .line-img {
    padding: 3px 13px;
  }
}
