.Highlight {
  min-height: 600px;
  background-image: linear-gradient(to bottom, #2422229f 30%, #1d1c1c8a 80%),
    url("/public/hightlight.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 50px;
}

@media (max-width: 640px) {
  .Highlight .span_12 {
    font-size: 13px !important;
  }
  .Highlight {
    padding: 40px 20px;
  }
}
@media (max-width: 370px) {
  .Highlight {
    padding: 40px 10px;
  }
}
