.AllProperties {
  min-height: 400px;
  color: #000000;
  padding: 20px 50px 40px 50px;
}

.AllProperties .h5 {
  font-size: 18px !important;
}
.AllProperties span {
  font-size: 12px;
  font-weight: 400;
}
.AllProperties p {
  font-size: 15px;
  font-weight: 400;
}

.AllProperties h5 {
  font-weight: 700;
  font-size: 18px;
  /* margin-bottom: 1.4rem; */
}
.AllProperties h2 {
  font-weight: 600;
  font-size: 32px;
  padding: 2rem 0;
}

/* import './Pagination.css' */

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 10px;
}

.pagination__react .page-num {
  margin: 0;
  padding: 6px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
}

.pagination__react .previous,
.pagination__react .next {
  margin: 0;
  padding: 8px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
}

.pagination__react .active {
  color: #fbc344;
}

/* new update */

.card-xRow {
  height: 260px;
  /* width: 100%; */
}

.card-xRow .card-xbody {
  padding: 1rem;
  width: 60%;
}

.card-xRow h5 {
  font-size: 14px !important;
}

.card-xRow .card-xImg {
  /* width: 60%; */
}
.card-xRow span {
  font-size: 14px;
}

/* new update */

@media (max-width: 640px) {
  .card-xRow {
    height: auto;
  }

  .card-xRow .card-xbody {
    width: 100%;
  }

  .AllProperties {
    padding: 40px 5px;
  }

  .AllProperties h2 {
    font-size: 22px;
    padding: 1rem 0;
    text-align: center;
  }

  .card-title b {
    font-size: 15px;
  }
  .AllProperties span {
    font-size: 13px;
  }
  .AllProperties p b {
    font-size: 13px;
  }
  .AllProperties p {
    font-size: 13px !important;
  }

  /* Paginate */
  .pagination__react .page-num {
    padding: 4px;
    margin-right: 1px;
  }

  .pagination__react .previous,
  .pagination__react .next {
    padding: 4px;
    cursor: pointer;
  }

  .pagination__react {
    margin: 0px !important;
  }
}
