/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Young+Serif&display=swap");

/* font-family: "Instrument Serif", serif;
font-family: "Young Serif", serif; */

/* General Hero Wrapper Styles */
.hero-wrapper {
  width: 100%;
  height: 80vh; /* 85% viewport height */
  background: transparent !important;
  padding-bottom: 2rem;
}

#home_id {
  position: relative;
  overflow-x: clip;
  background: linear-gradient(to bottom, #ffffffb3, #efe9e917) !important;
  color: #000;
}

#home_id > :nth-child(1) {
  color: #000000;
}

.hero-wrapper .carousel-control-next-icon,
.hero-wrapper .carousel-control-prev-icon {
  display: none;
}

#home_id .project-slide {
  position: relative;
  width: 100%;
  height: 555px; /* 85% viewport height */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
}

#home_id .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    #ffffffeb 1%,
    rgba(0, 0, 0, 0.009) 19%,
    rgba(0, 0, 0, 0.589) 95%
  ); /* Light to dark gradient overlay */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

#home_id .caption-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

#home_id .description {
  flex: 2;
  margin-right: 18px;
  margin-top: 7.4rem;
  text-align: left;
  color: white; /* Ensure text is readable on dark overlay */
}

#home_id .description h3 {
  font-family: "Young Serif", serif;
  font-size: 39px;
  font-weight: 400;
}

#home_id .description p {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
  padding-right: 5rem;
}

#home_id .form-container {
  flex: 1; /* Dark overlay for the form */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

#home_id .project-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 75%;
  background: #99999980;
  border-radius: 8px;
  font-family: "Young Serif", serif;
}

#home_id .project-form h4,
#h-font {
  font-family: "Young Serif", serif !important;
  font-size: 20px !important;
  font-weight: 400;
}

#home_id .project-form input {
  font-family: "Young Serif", serif;
  padding: 10px;
  border: 2px solid white;
  color: #000;
  border-radius: 5px;
  background: white;
  font-size: 12px;
  font-weight: 500;
}

#home_id .project-form input::placeholder {
  font-family: "Young Serif", serif;
  color: #b1b1b1;
  font-size: 12px;
  font-weight: 500;
}

#home_id .project-form input:focus-visible {
  border: none !important;
  border-color: white !important;
}

#home_id .project-form .btn {
  padding: 10px 20px;
  background-color: #fbc344 !important;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}

#home_id .project-form .btn:hover {
  background-color: #0056b3;
}

#home_id .carousel-indicators {
  background: #555555b5;
  width: 80px;
  text-align: center;
  margin-left: 47%;
  border-radius: 8px;
}

#home_id .carousel-indicators .active {
  background-color: #fff !important;
  background: #fff !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 10% !important;
  background-clip: content-box !important;
}

#home_id .carousel-indicators [data-bs-target] {
  height: 8px !important;
  width: 8px !important;
  border-radius: 1000px !important;
  background-clip: content-box;
  background-color: #000;
}

/* Iframe style */

/* Media Queries for Responsive Design */
/* Tablet View */
@media (max-width: 768px) {
  #home_id .project-slide {
    height: 60vh; /* Adjust height for smaller screens */
  }

  #home_id .gradient-overlay {
    flex-direction: column; /* Stack content vertically */
    padding: 14px;
  }

  #home_id .caption-content {
    flex-direction: column;
    align-items: flex-start;
  }

  #home_id .description {
    flex: unset;
    margin-bottom: 60px;
    width: 100%;
  }

  #home_id .form-container {
    flex: unset;
    width: 100%;
    padding: 20px;
  }

  #home_id .project-form {
    width: 100%;
    gap: 8px;
  }

  #home_id .project-form input {
    padding: 5px;
  }

  #home_id .project-form .btn {
    padding: 5px 10px;
  }

  #home_id .hero-wrapper {
    height: 70vh; /* Adjust height for tablet view */
  }
}

/* Mobile View */
@media (max-width: 460px) {
  #home_id .caption-content {
    margin-top: -15px;
    padding-top: 0px !important;
    padding-bottom: 10px !important;
    width: 91%;
    align-items: center;
    padding-right: 11px;
  }

  #home_id .project-slide {
    height: 78vh; /* Adjust height for smaller screens */
  }

  #home_id .description h3 {
    font-size: 2.1rem;
    text-align: center;
    margin-top: 0px;
    background: #14171a24;
    margin-right: -4px;
  }

  #home_id .project-form {
    width: 100%;
    margin-top: -25px;
  }

  #home_id .description p {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    padding-right: 1rem; /* Reduce padding on smaller screens */
    display: none;
  }

  /* #home_id .carousel-indicators {
    left: "20px" !important;
    margin-bottom: 1.4rem;
    margin-top: 1.3rem;
  } */
  .carousel-indicators {
    left: "-20px" !important;
    margin-bottom: 1.4rem;
    margin-top: 1.3rem;
    display: none;
  }

  #home_id .carousel-indicators .project-form {
    margin-top: 0px;
  }

  #home_id .project-form input {
    padding: 5px;
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  #home_id .project-form .btn {
    padding: 5px 10px;
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  #home_id .hero-wrapper .carousel-inner {
    width: 100%;
    height: 100% !important;
  }

  #home_id .hero-wrapper p {
    display: none;
  }

  #home_id .hero-wrapper {
    width: 100%;
    height: 65vh; /* Adjust height for mobile view */
  }
}

@media (max-width: 380px) {
  #home_id .project-slide {
    height: 89vh; /* Adjust height for smaller screens */
  }

  #home_id .description h3 {
    font-size: 2.2rem;
  }
  #home_id .project-form {
    width: 98%;
    margin-top: -69px;
  }
}
