:where(.css-dev-only-do-not-override-1uweeqc).ant-flex-align-stretch {
  align-items: stretch;
  background-color: #000 !important;
  color: #fff !important;
}

.full-width {
  width: 100% !important;
  height: 100vh !important;
  background: #000;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-spin-nested-loading
  > div
  > .ant-spin {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 4 !important;
  display: block;
  width: 100% !important;
  height: 100vh !important;
  max-height: 739px !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-spin-nested-loading {
  position: relative !important;
  width: 100% !important;
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-spin-nested-loading
  > div
  > .ant-spin
  .ant-spin-dot {
  position: absolute;
  top: 60% !important;
  inset-inline-start: 50% !important;
  margin: -10px !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-spin-nested-loading
  > div
  > .ant-spin
  .ant-spin-text {
  position: absolute;
  top: 50% !important;
  width: 100% !important;
  text-shadow: 0 1px 2px #ffffff;
  color: #fff !important;
  font-size: 20px !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-spin .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px !important;
  height: 9px !important;
  background-color: #ffffff;
  font-size: 18px !important;
  width: 30px !important;
  height: 30px !important;
}

:where(.css-1uweeqc).ant-spin {
  color: #fff !important;
}

:where(.css-1uweeqc).ant-spin-nested-loading > div > .ant-spin {
  background: #000;
}

:where(.css-1uweeqc).ant-spin .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px !important;
  height: 9px !important;
  background-color: #ffffff;
  font-size: 18px !important;
  width: 30px !important;
  height: 30px !important;
}

/* .preloader-now {
  height: 100vh;
  width: 100vw;
  background: #000;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preloader-now .text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 200px;
  font-size: 20px;
  font-weight: 800;
  overflow: hidden;
  color: #fff;
} */
