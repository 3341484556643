/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap"); */

@font-face {
  font-family: MaderaMedium;
  src: url(/public/Original_font/Madera/Madera\ Medium.ttf);
  /* src: url(/public/Madera_Complete_Family_Pack/Madera-Medium.ttf); */
}
@font-face {
  font-family: ClashDisplay;
  src: url(/public/Original_font/ClashDisplay_Complete/Fonts/OTF/ClashDisplay-Medium.otf);
  /* src: url(/public/Fonts/OTF/ClashDisplay-Medium.otf); */
}

:root {
  --primary: #1f3e72;
  --secondary: rgba(255, 255, 255, 0.78);
  --black: #131110;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: MaderaMedium;
  /* font-family: "Montserrat", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 15px;
}

#root {
  position: relative;
}
#root .FloatWhatsapp {
  position: sticky;
  bottom: 6%;
  left: 50%;
  margin-right: 1rem;
  z-index: 1000;
  height: 0;
}

/* code {
  font-family: myFont;
   font-family: "Montserrat", sans-serif; 
} */

/* universal */
/*  */
.less460 {
  display: none !important;
}
.location_wrap {
  position: relative !important;
}
.location_wrap .location_rotate {
  position: absolute !important;
  bottom: 41%;
  left: -31%;
  height: 47px;
  width: 220px;
  background: #f6f4f4;
  transform: rotate(270deg);
  padding: 0px 10px;
  color: #000;
  /* min-width: 200px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  border: none !important;
}
/*  */

.px-5no,
.px-5x,
.px-5xr {
  padding-right: 3rem;
  padding-left: 3rem;
}

.py-5x {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.my-5x {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.mt-5x {
  padding-top: 3rem;
}

.gap-4x {
  gap: 1.4rem;
}
.gap-5x {
  gap: 3rem;
}

/* .un-input {
  height: 50px;
  padding: 0 150px;
} */

.un-input {
  height: 50px;
  padding: 1px 168px;
}

.font_30 {
  font-size: 30px;
}
.font_15 {
  font-size: 15px;
}
.font_16 {
  font-size: 16px;
}
.font_22 {
  font-size: 22px;
}

/* .home-blog .swiper-button-next:after,
.home-blog .swiper-button-prev:after,
.home-weekly-props .swiper-button-next:after,
.home-weekly-props .swiper-button-prev:after {
  background: #fbc34499;
  padding: 10px 20px;
  border-radius: 200px;
  color: #ffffff;
} */
.swiper-button-next:after,
.swiper-button-prev:after,
.swiper-button-next:after,
.swiper-button-prev:after {
  background: #fbc34499;
  padding: 15px 20px;
  border-radius: 200px;
  color: #ffffff;
  font-size: 20px !important;
  font-weight: 900;
}

.css-1636szt {
  color: #fbc344 !important;
}

.css-fvipm8 {
  font-family: MaderaMedium !important;
  font-weight: 400 !important;
}

/* universal */

h1,
h2,
h3,
h4 {
  font-family: ClashDisplay;
}

h2 {
  font-size: 2rem;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-select {
  font-family: MaderaMedium;
}

.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: rgb(140 139 139);
  font-size: 0.9rem;
}
.orangeText {
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}

/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 1190px) {
  h2 {
    font-size: 2rem;
    font-weight: 600;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 740px) {
  .px-5no {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .display-none {
    display: none !important;
  }
  .font_30 {
    font-size: 19px;
  }
}

@media (max-width: 640px) {
  .location_wrap .location_rotate {
    bottom: 19.5%;
    left: -27%;
    /* padding: 9px; */
    height: 40px;
    width: 177px;
    /*  */
    display: flex;
    justify-content: center;
  }

  .location_wrap .location_rotate span {
    font-size: 13px;
  }

  .py-wrap {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
  }

  #search-btn {
    padding: 7px 13px;
    width: 100%;
  }

  #search-btn a {
    font-size: 13px;
  }

  /* universal */

  svg {
    font-size: 18px;
  }

  /* Universal container */
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: 0.3rem;
  }

  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}

.button {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--blue-gradient);
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
}
.button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (max-width: 640px) {
  .mt-5x {
    padding-top: 1.5rem;
  }
  .card_img_selc {
    width: 96% !important;
  }
  .card_img_selc img {
    width: 100%;
  }

  .font_15 {
    font-size: 13px !important;
  }

  .font_16 {
    font-size: 14px !important;
  }
  .font_22 {
    font-size: 18px !important;
  }
  /* universal */
  .my-5x,
  .py-5x {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .px-5x {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
  .px-5xr {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .un-input {
    height: 38px;
    padding: 0 10px;
  }

  h2 {
    font-size: 1.9rem;
  }

  h1 {
    font-size: 2.9rem;
  }
  /* universal */
  .gap-4x {
    gap: 0.8rem;
  }
  .gap-5x {
    gap: 1rem;
  }
  /* universal */

  .swiper-button-next:after,
  .swiper-button-prev:after,
  .swiper-button-next:after,
  .swiper-button-prev:after {
    padding: 5px 10px;
  }
}

@media (max-width: 460px) {
  body {
    overflow-x: hidden;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
    /* overflow-x: hidden !important; */
  }

  .container-fluid {
    overflow-x: hidden !important;
  }

  .less460 {
    display: block !important;
  }
  h2 {
    font-size: 1.18rem;
    font-weight: 600;
    line-height: 29px;
  }
  .un-input {
    height: 45px;
  }

  .location_wrap .location_rotate {
    bottom: 19.5%;
    left: -27%;
    /* padding: 9px; */
    height: 40px;
    width: 177px;
    /*  */
    display: flex;
    justify-content: center;
  }
}
/* @media (max-width: 450px) {
  .location_wrap .location_rotate {
    left: -33%;
  }
}
@media (max-width: 370px) {
  .px-5x {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
  .px-5xr {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
  }

  .gap-4x {
    gap: 0.6rem;
  }
} */
