.f-wrapper {
  min-height: 400px;
  background-color: #616161;
  color: #ffffff;
  margin-bottom: -3rem;
}

.f-wrapper h5 {
  font-size: 1.1rem;
}
.f-wrapper span,
.f-wrapper p {
  font-size: 15px;
}
.f-wrapper svg {
  font-size: 20px;
  cursor: pointer;
}

.footer-1st-row {
}
.footer-1st-row span {
  font-weight: 400;
  font-size: 15px;
}
.footer-2nd-row .footer-list {
  --bs-list-group-bg: none;
  --bs-list-group-border-color: none;
  color: #ffffff !important;
}
.footer-2nd-row .footer-list li {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 15px;
}

.back-wrap {
  padding: 20px;
  width: 100px;
}

.back-wrap .back-top {
  background-color: #fbc34499;
  padding: 10px;
  color: #ffffff;
  border-radius: 200px;
  cursor: pointer;
}
.back-wrap .back-top span {
  font-size: 20px;
  margin: 5px 10px;
}

.social-icon-wrap {
  color: #ffffff;
  padding: 1rem;
}
.social-icon-wrap .social-icon {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .f-wrapper h5 {
    font-size: 15px;
  }
  .f-wrapper span {
    font-size: 13px;
  }
  .footer-2nd-row .footer-list li {
    font-size: 13px;
  }
}
