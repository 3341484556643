.WhatPeepsSay {
}
.WhatPeepsSay h2 {
}
.WhatPeepsSay span {
  font-size: 13px;
}
.WhatPeepsSay .min_he .what_peep_wrap-1 .mark2 {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
