/* Radios.css */
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Adjust as needed for spacing */
}

.radio-label > span {
  margin-right: 8px; /* Adjust as needed for spacing */
}
