.PropReqForm {
  min-height: 500px;
  color: #000000;
  padding: 50px;
}
.PropReqForm
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: inherit !important;
  font-family: MaderaMedium;
  padding-left: 4rem;
}
.PropReqForm h4 {
  font-weight: 700;
  font-size: 32px;
}
.PropReqForm h5 {
  font-weight: 700;
  font-size: 15px;
}
.PropReqForm textarea,
.PropReqForm input {
  border: 1px solid #969696;
  border-radius: 9px;
}

@media (max-width: 640px) {
  .PropReqForm {
    padding: 10px;
    overflow: hidden;
  }
  .PropReqForm h4 {
    font-size: 16px;
  }
  .PropReqForm h5 {
    font-size: 12px;
  }
}
@media (max-width: 460px) {
  .PropReqForm button {
    padding: 15px !important;
    width: 50% !important;
  }
  .PropReqForm input {
    /* width: 90% !important; */
  }
  .PropReqForm textarea {
    /* width: 40% !important; */
  }

  .PropReqForm
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    /* width: 90% !important; */
    padding-left: 1rem;
  }
}
