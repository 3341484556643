.home-prop {
  background-color: #232424;
  min-height: 50vh;
}

.search-home .search-btn {
  background-color: #fbc344 !important;
  width: 100% !important;
  color: black !important;
}

.css-qiwgdb.css-qiwgdb.css-qiwgdb {
  padding-right: 32px;
  width: 155px;
}

/* default edit */
.home-prop .nav-pills .nav-link {
  background-color: #ffffff;
  color: #121212;
  font-size: 15px;
  font-weight: 400 !important;
}
.App--selected-blog .nav-pills .nav-link {
  background-color: #ffffff;
  color: #121212;
  font-size: 15px;
  font-weight: 400 !important;
}
.App--prop .nav-pills .nav-link {
  background-color: #ffffff;
  color: #121212;
  font-size: 15px;
  font-weight: 400 !important;
}

.App--selected-blog .nav-pills .nav-link.active,
.App--selected-blog .nav-pills .show > .nav-link,
.App--prop .nav-pills .nav-link.active,
.App--prop .nav-pills .show > .nav-link,
.home-prop .nav-pills .nav-link.active,
.home-prop .nav-pills .show > .nav-link {
  color: #000;
  background-color: #fbc344;
}

.select-width {
}

/* fbdhjfn */
.App--projs .nav-link {
  border-bottom: 2px solid white;
  font-size: 15px;
  font-weight: 500;
}

/* Yellow border bottom for the active Nav link */
.App--projs .nav-link.active {
  border-bottom: 2px solid #fbc344;
  color: #fbc344 !important;
}
.App--projs .proj-tab-bg {
  min-height: 300px;
  width: 100vw;
  background-image: linear-gradient(to bottom, #1312129f 70%, #050505f6 85%),
    url("/public/selected_proj_new.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-position: center;
}
.App--projs .proj-tab-bg h2 {
  font-weight: 600;
  font-size: 68px;
}
.App--projs .proj-tab-bg span {
  font-weight: 400;
  font-size: 15px;
}

/* placeholder */
:where(.css-dev-only-do-not-override-1b0bdye).ant-input-affix-wrapper
  > input.ant-input {
  font-family: MaderaMedium !important;
  font-size: 15px;
  font-weight: 400;
  /* color: #1212123b; */
  padding-left: 0.2rem;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-input-affix-wrapper
  > input.ant-input::placeholder {
  color: #1212123b;
  font-size: 15px;
  font-weight: 400;
  padding-left: 0.2rem;
  font-family: MaderaMedium !important;
}

/* Responsiveness */

@media (max-width: 1200px) {
}
@media (max-width: 1015px) {
}

@media (max-width: 940px) {
  /* Adjust styles for screens up to 940px */
}
@media (max-width: 700px) {
  /* Adjust styles for screens up to 940px */
  .select-width {
    margin: 5px 1rem;
  }
}

@media (max-width: 640px) {
  .btn_center {
    text-align: center;
  }
  /* Adjust styles for screens up to 640px */
  .proj-tab-bg h2 {
    font-size: 40px;
  }
  .proj-tab-bg span {
    font-size: 13px;
  }
}
@media (max-width: 640px) {
  /* Adjust styles for screens up to 640px */
  .proj-tab-bg h2 {
    font-size: 30px;
  }

  .App--projs .proj-tab-bg h2 {
    font-size: 27px;
  }
}
@media (max-width: 540px) {
}

@media (max-width: 460px) {
  #tab_1 {
    position: relative;
  }

  .nav-pills {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    /* margin-bottom: 2rem; */
    position: absolute;
    top: 45%;
    left: 2.8%;
    z-index: 10;
    padding: 1rem 0.3rem;
  }

  .nav-pills .nav-item {
    width: 118px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    padding: 0px !important;
  }
  /* .nav-pills .nav-item.active {
    
  } */
  .nav-pills .nav-item a {
    font-size: 14px !important;
  }

  .App--selected-blog .nav-pills .nav-link,
  .App--selected-blog .nav-pills .nav-link .App-prop .nav-pills .nav-link,
  .home-prop .nav-pills .nav-link {
    background-color: #ededed !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px !important;
    padding-top: 0.9rem !important;
  }

  .App--prop .nav-pills .nav-link {
    background-color: #ededed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App--selected-blog .nav-pills .nav-link.active,
  .App--selected-blog .nav-pills .show > .nav-link,
  .App--prop .nav-pills .nav-link.active,
  .App--prop .nav-pills .show > .nav-link,
  .home-prop .nav-pills .nav-link.active,
  .home-prop .nav-pills .show > .nav-link {
    color: #121212;
    background-color: #ededed;
    border: 2px solid #fbc344;
    width: 100%;
    /* padding: 0.51rem 2.4rem !important; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* vercel */
  .css-qiwgdb.css-qiwgdb.css-qiwgdb,
  .css-qiwgdb.css-qiwgdb.css-qiwgdb {
    padding-right: 32px;
    width: 231px;
  }
  /* vercel */

  .tab-content {
    padding-top: 8rem;
  }

  .select_wrap_find {
    flex-direction: column !important;
    /* justify-content: center;
    align-items: center; */
  }
  .select_wrap_find .select-width {
    /* width: 80%; */
  }

  :where(.css-dev-only-do-not-override-1b0bdye).ant-input-affix-wrapper
    > input.ant-input {
    font-size: 13px;
    margin-left: 1rem;
  }

  .App--projs .proj-tab-bg {
    min-height: 300px;
    width: 93vw;
    background-image: linear-gradient(to bottom, #1312129f 70%, #050505f6 85%),
      url("/public/selected_proj_new.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    background-position: center;
  }

  .wrap_flexx {
    flex-wrap: wrap;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 241px !important;
    height: 43px;
  }

  :where(.css-dev-only-do-not-override-1b0bdye).ant-input-affix-wrapper
    > input.ant-input::placeholder {
    font-size: 12p;
  }
}

@media (max-width: 380px) {
  /* vercel */
  .css-qiwgdb.css-qiwgdb.css-qiwgdb,
  .css-qiwgdb.css-qiwgdb.css-qiwgdb {
    padding-right: 32px;
    width: 200px;
  }
  /* vercel */
}
@media (max-width: 330px) {
  /* vercel */
  .css-qiwgdb.css-qiwgdb.css-qiwgdb {
    padding-right: 32px;
    width: 170px;
  }
  /* vercel */
}
