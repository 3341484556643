.home_footer {
  background-color: #000; /* Set background color to black */
  color: #fff;
  padding: 3rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Add this to allow wrapping on smaller screens */
}

.home_footer .container {
  width: 100%;
  max-width: 1200px;
}

.home_footer .footer-left,
.home_footer .footer-middle,
.home_footer .footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem; /* Add margin for spacing */
}

.home_footer .footer-left img {
  margin-bottom: 0.5rem;
}

.home_footer .footer-left p {
  font-size: 0.875rem;
}

.home_footer .footer-links,
.home_footer .social-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.home_footer .footer-links li,
.home_footer .social-links li {
  display: inline;
}

.home_footer .footer-links a,
.home_footer .social-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.home_footer .social-links .arrow {
  font-size: 0.875rem;
  color: #fbc344;
  display: inline-block;
  transform: rotate(70deg); /* Rotate the arrow */
  margin: 0.3rem;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .home_footer {
    padding: 2rem 1.5rem;
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center;
  }

  .home_footer .footer-left,
  .home_footer .footer-middle,
  .home_footer .footer-right {
    width: 100%; /* Make full width */
    text-align: center; /* Center text */
  }

  .home_footer .footer-links,
  .home_footer .social-links {
    align-items: center; /* Center align items */
  }
}

@media (max-width: 576px) {
  .home_footer {
    padding: 1rem 1rem;
  }

  .home_footer .footer-left p,
  .home_footer .footer-links a,
  .home_footer .social-links a {
    font-size: 0.875rem; /* Adjust font size for smaller screens */
  }
}
