.apartment-available {
  background-color: #000000;
  min-height: 600px;
  color: #ffffff;
  padding: 50px 10px 50px 20px;
}

.card-title-1 {
  font-size: 2rem;
  font-weight: 600;
}

@media (max-width: 640px) {
  .apartment-available {
    padding: 40px 10px;
  }

  .card-title-1 {
    font-size: 1.5rem;
    text-align: center;
  }
  .apartment-available {
    padding: 40px 10px;
    /* overflow-x: scroll; */
  }
  .apartment-available .rower_wrap {
    margin: 0 10px 0px 8px !important;
  }
  .apartment-available .line-img {
    padding: 5px 20px;
  }
}

@media (max-width: 460px) {
  .card-title-1 {
    font-size: 1.13rem;
  }

  .apartment-available {
    padding: 30px 0px;
  }
}
