.h-wrapper {
  /* z-index: 99;
  position: sticky;
  top: 0;
  bottom: 0; */
}
.h-container {
  padding-top: 0.3rem !important;
  padding-bottom: 0.6rem;
  /* color: var(--secondary); */
  justify-content: space-between;
}

.h-menu {
  gap: 4.1rem;
}
.h-menu a {
  font-size: 12px !important;
  font-weight: 500;
}
.h-menu > *:hover {
  cursor: pointer;
}
.menu-icon {
  display: none;
}

#about-mobile {
  display: none;
  padding: 7px 13px !important;
  background: #fbc344 !important;
  border-radius: 9px;
  color: #000000 !important;
}

.header-btn a {
  color: #000000 !important;
}

div .header-btn {
  background: #fbc344 !important;
  border-radius: 9px;
  color: #000000 !important;
  padding: 7px 13px;
  font-size: 12px;
  font-weight: 500;
}

.activeLink {
  border-bottom: 2px solid #fbc344;
  /* // You can customize other styles for the active link here */
}

@media (max-width: 900px) {
  .h-menu {
    gap: 2rem;
  }
  .h-wrapper {
    padding-top: 4px !important;
  }
  .h-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .h-menu {
    color: var(--black);
    position: absolute;
    gap: 2rem;
    font-weight: 500;
    flex-direction: column;
    right: 4rem;
    top: 3rem;
    background: white;
    display: flex;
    border-radius: 10px;
    transition: all 200ms ease;
    align-items: flex-start;
    padding: 3rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 1000;
  }
  #about-desktop {
    display: none;
    color: #000000;
  }
  div .header-btn {
    display: none;
  }
  #about-mobile {
    display: block;
  }
}

@media (max-width: 700px) {
  .h-wrapper img {
    width: 65px;
  }
}
@media (max-width: 460px) {
  .h-wrapper {
    padding: 5px 10px !important;
  }

  .menu-icon {
    cursor: pointer;
  }

  .h-menu {
    color: var(--black);
    position: absolute;
    gap: 2rem;
    font-weight: 500;
    flex-direction: column;
    right: 0rem;
    top: 5rem;
    background: white;
    display: flex;
    border-radius: 0px;
    transition: all 200ms ease;
    align-items: flex-start;
    padding: 3rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }

  .h-wrapper img {
    width: 46px;
    height: 35px !important;
  }

  svg {
    width: 25px;
    height: 20px;
  }
  .menu-icon.active {
    border: 1px solid #d9d9d933;
    background-color: #d9d9d933;
    padding: 3px;
  }
}
