/* #map-container {
  width: 100%;
  height: 600px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
} */

.map2 {
  width: 100% !important;
  height: 80vh !important;
  overflow-y: hidden;
  /* padding: 1rem; */
}

/* .mapboxgl-map {
  position: relative;
    width: 100%;
    height: 100%;
} */
/*  */
.SelectedProjVideo {
  min-height: 400px;
  /* padding: 50px; */
  background-color: #000000;
  padding: 0;
}
.SelectedPorjImg {
  min-height: 400px;

  padding: 50px;
  /* padding-top: 20rem; */
  background-color: #000000;
}

.SelectedProjMap {
  min-height: 300px;
  width: 100vw;
  padding: 0;
}

.SelectedPorjImg .btn:hover {
  background-color: inherit;
}

@media (max-width: 640px) {
  .pLeft {
    margin-left: 0px !important;
  }

  .SelectedPorjImg {
    padding: 30px 10px;
  }
  .SelectedPorjImg img {
    width: 100%;
    /* margin-bottom: 10px; */
  }

  .call_mess_btn button {
    width: 100%;
  }
  .share_btn {
    width: 70% !important;
  }
}

@media (max-width: 460px) {
  .SelectedPorjImg {
    padding: 20px 8px;
  }

  .call_mess_btn {
    flex-direction: column;
    margin: 0px;
  }

  .SelectedPorjImg .share_btn,
  .call_mess_btn button {
    width: 100% !important;
    padding: 14px;
    margin: 5px !important;
  }
  .SelectedPorjImg .share_btn {
    margin-top: 1rem !important;
  }
}
