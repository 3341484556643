.home-weekly-props {
  /* background-color: #ffffff; */
  min-height: 503px;
  padding: 50px 50px;
}
.home-blog {
  background-color: #e1e1e1 !important;
  min-height: 503px;
  padding: 50px 50px;
}

.weekly-props-header h2 {
  font-weight: 600;
  font-size: 32px;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  width: 1400px !important;
  transform: translate3d(0px, 0, 0);
}

.weekly-props-header button {
  font-weight: 500;
  font-size: 15px;
  color: #121212;
  border: 1px solid #121212;
}

/* .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: white !important;
} */

.row-btn button {
  font-family: MaderaMedium !important;
}

.row-btn button:hover {
  background-color: white;
}

/* Swiperjs */
.home-blog .swiper-button-next:after,
.home-blog .swiper-button-prev:after,
.home-weekly-props .swiper-button-next:after,
.home-weekly-props .swiper-button-prev:after {
  /* content: "\003c"; */
  background: #fbc34499;
  padding: 18px 25px;
  border-radius: 200px;
  color: #ffffff;
  font-size: 30px !important;
  font-weight: 900 !important;
  margin-bottom: 80px;
}

/* display */
@media only screen and (min-width: 768px) {
  .dflex_1st12 {
    display: none !important;
  }
}

/* Hide the second div on mobile */
@media only screen and (max-width: 767px) {
  .display-none12 {
    display: none !important;
  }
}

@media (max-width: 640px) {
  .home-weekly-props {
    padding: 30px 3px;
  }

  .weekly-props-header {
    flex-direction: column;
  }
  .weekly-props-header h2 {
    font-size: 25px;
    margin-bottom: 1rem;
  }

  .row-btn button {
    padding: 3px 10px !important;
    font-weight: 400;
  }

  .swipe_card_with {
    padding: 0px;
  }
  .swipe_card_with .card_body {
    padding: 10px;
  }
  .swipe_card_with .card_body b {
    font-size: 0.9rem;
    margin: 0;
  }
  .swipe_card_with .card_body span,
  .swipe_card_with .card_body span b {
    font-size: 0.79rem;
  }

  .swipe_card_with .card_body p,
  .swipe_card_with .card_body p b {
    font-size: 0.79rem !important;
    margin: 0px;
  }
  .home-blog .swiper-button-next:after,
  .home-blog .swiper-button-prev:after,
  .home-weekly-props .swiper-button-next:after,
  .home-weekly-props .swiper-button-prev:after {
    background: #fbc34499;
    padding: 5px 10px;
  }
}

@media (max-width: 460px) {
  .weekly-props-header h2 {
    font-size: 18px;
    font-weight: 600;
  }

  .row-btn {
    margin-top: -10px !important;
    padding-top: 0px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .row-btn button {
    width: 35% !important;
    height: 39px;
    /*  */
    background: #e7e7e7 !important;
    color: black;
    font-size: 14px;
  }

  /*  */
  .home-blog .swiper-button-next:after,
  .home-blog .swiper-button-prev:after,
  .home-weekly-props .swiper-button-next:after,
  .home-weekly-props .swiper-button-prev:after {
    background: #fbc34499;
    padding: 13px 18px;
  }

  .home-blog {
    padding: 30px 15px;
  }

  .home-blog h2 {
    margin-bottom: 2rem !important;
    text-align: center !important;
  }
  /*  */
  /* .swipe_card_with span {
    font-size: 13px !important;
  }
  .swipe_card_with b {
    font-size: 16px;
  } */

  /* Swiperjs */
  .home-blog .swiper-button-next:after,
  .home-blog .swiper-button-prev:after,
  .home-weekly-props .swiper-button-next:after,
  .home-weekly-props .swiper-button-prev:after {
    /* content: "\003c"; */
    background: #fbc34499;
    padding: 15px 20px;
    font-size: 16px !important;
    font-weight: 900 !important;
    margin-bottom: 80px;
  }
}

@media (max-width: 360px) {
  .club {
    margin: 0 0 !important;
  }
}
