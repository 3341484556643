.fit-content {
  width: fit-content;
}

/* modal */

.modal-content {
  min-height: 420px !important;
  margin-top: 5.4rem !important;
  padding: 15px !important;
  min-width: 450px !important;
}

.modal-content-1 {
}

.modal-header {
  border-bottom: none !important;
}
.modal-content .share_modal {
  border: 1px solid #d7d7d7;
  /* padding: 10px; */
  border-radius: 200px;
  width: 55px;
  cursor: pointer;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content .share_modal a {
  padding: 10px;
}

.modal-header .btn-close {
  background-color: #f1f1f1 !important;
  border-radius: 200px !important;
}

/* Select */
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  left: 5px;
  top: -4px;
} */

.price_edited {
  color: #12121273 !important;
  line-height: 22.68px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: MaderaMedium !important;
  left: 5px !important;
  top: -4px !important;
}

.css-13sljp9 {
  min-width: 157px !important;
}

.css-fvipm8 {
  max-height: 45px !important;
}

.css-qiwgdb {
  font-family: MaderaMedium !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #12121273 !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fbc344 !important;
  width: 1.2em !important;
  height: 1.2em !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  /* height: 45px;
  width: 190px; */
  /* margin: 1rem; */
  font-family: MaderaMedium !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 185px !important;
  height: 45px !important;
  font-family: MaderaMedium !important;
  border-radius: 9px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root em {
  font-family: MaderaMedium !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #12121273 !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root span {
  font-family: MaderaMedium !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #12121273 !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-size: 15px !important;
  color: #12121273 !important;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important; /* Set the desired opacity for disabled buttons */
  cursor: pointer !important;
  pointer-events: auto !important;
}

.swiper-container {
  overflow: hidden;
  touch-action: pan-y;
}

/* Media query */

@media (max-width: 460px) {
  .price_edited {
    line-height: 15.68px !important;
    font-size: 14px !important;
    /* left: 5px !important;
    top: -4px !important; */
  }
  /*  */
  .modal-content {
    min-width: 371px !important;
    min-height: 344px !important;
  }
  /* slide img */
  .m-img {
    width: 203px !important;
  }

  /*  */
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 271px !important;
    height: 45px !important;
  }
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root em {
    margin-left: 1rem;
    font-size: 13px !important;
  }
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root span {
    margin-left: 1rem;
    font-size: 13px !important;
  }

  .css-qiwgdb {
    font-size: 13px !important;
    color: #12121273 !important;
  }

  .row > * {
    /* padding-right: 10px; */
    /* padding-left: 0.3rem; */
    margin-left: 0;
    margin-right: 0;
  }

  .swiper {
    padding: 15px !important;
  }
}
/* @media (max-width: 420px) {
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 220x !important;
    height: 45px !important;
  }
} */

@media (max-width: 400px) {
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 221px !important;
  }
}
