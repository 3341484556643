.mobile-slider {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  position: relative;
}

.mobile-slider .slide {
  flex: 0 0 33.33%;
  margin: 0 10px;
  scroll-snap-align: start;
  width: 100%;
  height: 150px;
}

.mobile-slider .slide-mob-btn {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mobile-slider .slide-mob-btn:first-child {
  left: 0;
}

.mobile-slider .slide-mob-btn:last-child {
  right: 0;
}

/* weekly props */
.weekly-mobile-slider {
  position: relative;
  overflow: hidden;
}

.weekly-mobile-slider .slide {
  display: none;
}

.weekly-mobile-slider .slide.active {
  display: block;
}

.weekly-mobile-slider button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.weekly-mobile-slider button:first-child {
  left: 0;
}

.weekly-mobile-slider button:last-child {
  right: 0;
}

/* btn slide */
.button-prev-1 {
  /* background: #fbc34499 !important; */
  /* padding: 15px 20px; */
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-bottom: 80px;
  color: #fff;
  /* border-radius: 50px; Changed from 200px to 50px */
  margin-left: -5px;
}

/* Add this to remove the default button style */
.button-prev-1 {
  border: none;
  cursor: pointer;
}
.button-prev-2 {
  /* background: #fbc34499 !important; */
  /* padding: 15px 20px; */
  font-size: 16px !important;
  font-weight: 900 !important;
  margin-bottom: 80px;
  color: #fff;
  /* border-radius: 50px; Changed from 200px to 50px */
  margin-right: -5px;
}

/* Add this to remove the default button style */
.button-prev-2 {
  border: none;
  cursor: pointer;
}
