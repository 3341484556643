@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Young+Serif&display=swap");

/* font-family: "Instrument Serif", serif;
  font-family: "Young Serif", serif; */

.about_home {
  padding: 70px 70px;
}

.about_home .row {
  padding: 30px 20px !important;
  background: #f1f2f4;
  border-radius: 20px;
  gap: 1.5rem;
  padding-left: 54px !important;
}

.about_home .col_top {
  background-image: url("/public/jea-about-1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 8px;
  border-radius: 16px !important;
}

.about_home .col_top h3 {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 36px;
  text-align: center;
  padding: 40px;
}

.about_home .col_middle {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
  padding-top: 50px;
}

.about_home .col_middle h2 {
  font-family: "Young Serif", serif;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
}

.about_home .col_middle p {
  font-family: serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.about_home .col_middle button {
  font-family: "Young Serif", serif;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-left: 35%;
  margin-top: 1rem;
}

.about_home .col_last {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.about_home .col_last div {
  width: 100%;
  background-color: #fff;
  padding: 15px 15px;
  border-radius: 16px;
}

.about_home .col_last div h3 {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 30px;
  text-align: center;
}

.about_home .col_last img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

/* Tablet View */
@media (max-width: 768px) {
  .about_home {
    padding: 50px 20px;
  }

  .about_home .row {
    padding: 20px 10px !important;
    padding-left: 20px !important;
  }

  .about_home .col_top {
    padding: 30px 8px;
  }

  .about_home .col_top h3 {
    font-size: 28px;
    padding: 20px;
  }

  .about_home .col_middle {
    padding: 20px;
    padding-top: 30px;
  }

  .about_home .col_middle h2 {
    font-size: 28px;
  }

  .about_home .col_middle p {
    font-size: 12px;
  }

  .about_home .col_middle button {
    margin-left: 25%;
  }

  .about_home .col_last {
    padding: 15px 10px;
  }

  .about_home .col_last div h3 {
    font-size: 24px;
  }

  .about_home .col_last img {
    height: 150px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .about_home {
    padding: 30px 10px;
  }

  .about_home .row {
    padding: 15px 5px !important;
    padding-left: 10px !important;
  }

  .about_home .col_top {
    padding: 20px 5px;
    height: 220px;
    break-after: 8px;
  }

  .about_home .col_top h3 {
    font-size: 24px;
    padding: 10px;
  }

  .about_home .col_middle {
    padding: 40px;
  }

  .about_home .col_middle h2 {
    font-size: 24px;
  }

  .about_home .col_middle p {
    font-size: 10px;
  }

  .about_home .col_middle button {
    margin-left: 31%;
    font-size: 12px;
  }

  .about_home .col_last {
    padding: 10px 5px;
  }

  .about_home .col_last div h3 {
    font-size: 20px;
  }

  .about_home .col_last img {
    height: 250px;
  }
}
