.AboutVideo {
  min-height: 50vh;
  color: #000000;
}
.about_banner {
  min-height: 220px;
  color: #ffffff;
  background-image: linear-gradient(to bottom, #000000ad, #000000ad),
    url("/public/new_prop_about.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-boss-1 {
  min-height: 720px;
  color: #000000;
  background-color: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  /* url("/public/Boss-1.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
.founder_new {
  background-image: url("/public/founderImg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.AboutImages {
  padding: 40px;
}
.WhatPeepsSay {
  padding: 40px;
}

.AboutVideo h3 {
  font-weight: 600;
  font-size: 32px;
}
.AboutVideo p {
  font-weight: 400;
  font-size: 15px;
}
.AboutVideo .core-val {
  background-color: #e1e1e1;
  padding: 40px;
}
.AboutVideo .affil-sub {
  background-color: #000000;
  padding: 200px;
  color: #ffffff;
  text-align: center;
  min-height: 400px;
}

/* what_peep_wrap-1 */
.what_peep_wrap-1 {
  background-color: #ebebeb;
  text-align: center;
  position: relative;
  min-height: 360px;
  padding: 29px 40px;
}
.what_peep_wrap-1 span,
.what_peep_wrap-1 p {
  font-weight: 400;
  font-size: 14px;
}
.what_peep_wrap-1 .top-img {
  border-radius: 200px !important;
  /* border: 15px solid #fff; */
  display: flex;
  justify-content: center;
}
.what_peep_wrap-1 .top-img img {
  width: 80px;
  height: 80px;
  border-radius: 200px;
  outline: 15px solid #fff;
  margin-bottom: 2.5rem;
  margin-top: -63px;
}
/* what_peep_wrap-1 */

.comp-logo {
  /* min-height: 300px; */
  color: #ffffff;
  background-image: linear-gradient(to bottom, #111111a2, #111111a2),
    url("/public/news-letter-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.font_18 {
  font-weight: 500;
  font-size: 18px;
}
.font_15 {
  font-weight: 400;
  font-size: 15px;
}
.font_12 {
  font-size: 13px;
  font-weight: 300;
}

.img_padding {
  padding: 0px 80px;
}

.work_us {
  background-color: #e1e1e1;
}
.work_us-img {
}
.work_us-text {
  padding: 10px 100px;
}

@media (max-width: 640px) {
  .AboutImages_our_agent {
    margin-left: 5px;
  }

  .AboutVideo h3 {
    font-size: 22px;
  }

  .AboutImages {
    padding: 10px;
    margin-left: -13px;
  }

  .AboutVideo .AboutVideo_wrap {
    padding-bottom: 0px !important;
  }

  .AboutVideo h2 {
    font-size: 23px;
    margin-top: 0.4rem !important;
    /* margin-bottom: 2rem !important; */
  }

  .AboutVideo .affil-sub {
    padding: 20px 10px;
  }
  .AboutVideo .affil-sub h3 {
    font-size: 22px;
    margin: 10px 0px !important;
  }
  .AboutVideo .affil-sub p {
    font-size: 13px;
  }

  .AboutVideo_1st_col {
    order: 1;
  }
  .AboutVideo_1st_col img {
    width: 100%;
  }
  .AboutVideo_2nd_col {
    order: 2;
  }

  .work_us-img img {
    width: 100%;
  }

  .work_us-text {
    padding: 20px;
  }

  .img_padding {
    padding: 0px 0px;
  }

  .img_padding img {
    width: 220px !important;
    height: 180px !important;
  }

  .AboutImages {
    padding: 0 5 0 15px;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .what_peep_wrap-1 {
    padding: 55px 30px;
  }

  .WhatPeepsSay {
    padding: 20px;
  }
}
@media (max-width: 460px) {
  .dak_p {
    /* font-weight: bold; */
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff !important;
    /* padding: 0; */
    margin: 0;
  }
  .align-p {
    text-align: justify;
  }
  .AboutImages .first-mg img {
    height: 220px !important;
    width: 350px !important;
    margin: 0 !important;
    margin-bottom: 0.7rem !important;
  }
  .what_peep_wrap-1 span,
  .what_peep_wrap-1 p {
    font-weight: 400;
    font-size: 13px;
  }

  .what_peep_wrap-1 {
    padding: 30px 16px;
  }

  .img_padding div {
    margin-bottom: 0.6rem;
  }

  /* Boss */
  .bg-boss-1 {
    min-height: 420px;
    color: #000000;
    background-image: linear-gradient(to top, #fdfbfb7e, #f3f0f077),
      url("/public/founderImg.jpg");
    background-size: 200%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 9px;
  }
  .bg-boss-1 .row .dak_p h2 {
    padding-bottom: 2rem !important;
  }
  .bg-boss-1 p {
    font-size: 11px !important;
    text-align: justify;
  }
}
