.location-props {
  background-color: #ffffff;
  min-height: 500px;
  padding: 70px 60px;
}
.location-props .div_1 {
  margin-right: -55px;
}
.location-props svg {
  font-size: 1.6rem;
}
.location-props .btn:hover {
  color: #000;
  background-color: transparent !important;
}

/* hehsdan */
.location-props .swiper-button-next:after,
.location-props .swiper-button-prev:after,
.location-props .swiper-button-next:after,
.location-props .swiper-button-prev:after {
  background: transparent;
  padding: 15px 20px;
  border-radius: 200px;
  color: transparent;
  font-size: 20px !important;
  font-weight: 900;
  pointer-events: none;
}

@media (max-width: 640px) {
  .location-props #search-btn {
    /* margin-left: 1rem; */
    font-size: 14px;
  }

  .location-props {
    padding: 40px 10px;
  }
  .location-props {
    padding: 40px 5px;
  }
  .location-props .div_1 {
    margin-right: 0px;
  }

  .location-props button.btn-primary {
    margin-top: 1.1rem;
  }
}
