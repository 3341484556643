.BlogBody {
  padding: 50px;
  color: #000;
}

@media (max-width: 640px) {
  .wp-block-image img {
    margin: 1.3rem;
    width: 300px !important;
    height: 200px !important;
  }
  .BlogBody {
    padding: 20px;
  }
  .BlogBody span,
  .BlogBody p {
    font-size: 13px !important;
    margin: 0px !important;
  }
  .BlogBody h3,
  .BlogBody h5 {
    font-size: 18px !important;
  }

  .BlogBody button {
    width: 100%;
  }
}
