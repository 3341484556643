.CantFind {
  min-height: 350px;
  padding: 50px;
  color: #ffffff;
  background-image: linear-gradient(to bottom, #000000ad 20%, #000000ad 80%),
    url("/public/cant-find.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.NewsLetter {
  min-height: 350px;
  padding: 50px 80px;
  color: #ffffff;
  background-image: linear-gradient(to bottom, #1111119f 20%, #111111af 80%),
    url("/public/news-letter-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.NewsLetter h4 {
  font-weight: 600;
  font-size: 32px;
}
.NewsLetter span {
  font-weight: 400;
  font-size: 13px;
}
.NewsLetter button {
  font-weight: 500;
  font-size: 15px;
  padding: 13px 25px;
}

.make_req {
  width: 50%;
}

@media (max-width: 640px) {
  .CantFind {
    padding: 10px;
  }
  .NewsLetter {
    padding: 40px 10px;
  }

  .NewsLetter h4 {
    font-size: 22px;
  }
  .NewsLetter span {
    font-size: 13px;
  }

  .cant_D {
    justify-content: center;
  }

  .make_req {
    width: 80%;
    font-size: 16px;
  }
  .NewsLetter button {
    font-size: 13px;
    padding: 6px 22px;
    margin-left: 1em;
  }
}
