@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Young+Serif&display=swap");

/* font-family: "Instrument Serif", serif;
font-family: "Young Serif", serif; */

.feactured_proj_home {
  min-height: 60vh;
  background-image: url("/public/featured-proj.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100% !important;
  margin-top: 2rem;
}

.feactured_proj_home h2 {
  font-family: "Young Serif", serif;
  padding-top: 1.5rem;
}

.feactured_proj_home h2,
.feactured_proj_home p {
  text-align: center;
  margin-top: 7rem;
}

.feactured_proj_home p {
  padding-left: 355px;
  padding-right: 355px;
  margin-bottom: 30px;
  margin-top: 0px;
}

.bg-image {
  background-size: cover;
  background-position: center;
  height: 500px;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
  border-radius: 16px;
  margin: 3px 5px;
}

.bg-image:hover {
  transform: scale(0.95); /* Zoom-out effect on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    #21141434,
    #0b0a0ab7
  ); /* Dark overlay gradient */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  border-radius: 16px;
  padding: 20px 20px 60px 20px;
  text-align: center;
  font-family: "Young Serif", serif;
}

.feactured_proj_home .title {
  font-family: "Young Serif", serif;
  font-size: 24px;
  font-weight: 400;
}

/* Tablet View */
@media (max-width: 768px) {
  .feactured_proj_home {
    margin-top: 6rem;
  }
  .feactured_proj_home p {
    padding-left: 20px;
    padding-right: 20px;
  }

  .feactured_proj_home h2 {
    font-size: 24px;
    margin-top: 0px;
  }

  .bg-image {
    height: 400px;
    width: 90%;
  }

  .overlay {
    padding: 15px 15px 40px 15px;
  }

  .feactured_proj_home h2,
  .feactured_proj_home p {
    margin-top: 5rem;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .feactured_proj_home {
    padding: 20px 10px !important;
  }

  .feactured_proj_home p {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    margin-top: 0.7rem !important;
  }

  .feactured_proj_home h2 {
    margin-top: 3.6rem !important;
    font-size: 26px;
  }

  .bg-image {
    height: 400px;
    width: 96%;
  }

  .overlay {
    padding: 10px 6px 30px 6px;
  }

  .feactured_proj_home button {
    padding-bottom: 10px;
    padding-top: 9px;
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 380px) {
  .feactured_proj_home {
    margin-top: 7.4rem;
  }
  .feactured_proj_home h2 {
    margin-top: 4.3rem !important;
  }
  .feactured_proj_home p {
    padding-left: 7px;
    padding-right: 7px;
    margin-top: -5px !important;
  }
}
