@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Young+Serif&display=swap");

/* font-family: "Instrument Serif", serif;
font-family: "Young Serif", serif; */

.search-home-2 {
  min-height: 400px;
  width: 100%;
  background-image: url("/public/search-Images.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-position: center;
  padding: 50px 40px 50px 40px;
}

.search-home-2 .bg-white-2 {
  border-radius: 8px;
  background-color: #fff;
  padding: 25px;
}

.search-home-2 .searc_home_tab {
  background-color: #f2f2f2;
  padding: 20px 30px;
  border-radius: 16px;
}

.search-home-2 .searc_home_tab #left-tabs-example-tabpane-first {
  background-color: #f2f2f2 !important;
  padding: 0;
}

.search-home-2 .searc_home_tab .top_row {
  background-color: #fff;
  width: 40%;
  margin-bottom: 1rem;
  justify-content: center;
  padding: 4px !important;
  border-radius: 8px !important;
}

.search-home-2 .searc_home_tab .top_row .nav-pills {
  font-family: "Young Serif", serif;
  font-size: 13px;
  font-weight: 400;
  color: #9f9f9f !important;
  padding: 0.35rem 0.38rem;
  gap: 0.4rem;
}

.search-home-2 .searc_home_tab .top_row .nav-pills .nav-link {
  border-radius: 8px;
  color: #9f9f9f;
  background-color: #f9f9f9;
}

.search-home-2 .searc_home_tab .top_row .nav-pills .nav-pills .nav-link {
  color: #9f9f9f !important;
  padding: 0px !important;
}

.search-home-2 .searc_home_tab .top_row .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #0e0f0f !important;
  background-color: #fbc344;
}

.search-home-2
  .bottom_row
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100% !important;
  height: 45px !important;
  font-family: "Young Serif", serif !important;
  border-radius: 9px !important;
}

.search-home-2 .bottom_row .select-width {
  height: 45px !important;
  font-family: "Young Serif", serif !important;
  border-radius: 9px !important;
}

.search-home-2
  .bottom_row
  .MuiFormControl-root
  .css-1nrlq1o-MuiFormControl-root {
  height: 45px !important;
  font-family: "Young Serif", serif !important;
  border-radius: 9px !important;
}

.search-home-2 input,
.search-home-2 .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.search-home-2 .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.search-home-2 select .price_edited {
  color: #12121273 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Young Serif", serif !important;
}

.search-home-2 .un-input {
  height: 41px;
  padding: 1px 168px;
  margin-bottom: 0.4rem;
  background-color: #ffffff !important;
}

.bg-white-2 h4 {
  font-family: "Young Serif", serif;
  font-size: 30px;
  font-weight: 400;
}

.bg-white-2 button {
  font-family: "Young Serif", serif;
  font-size: 13px;
  font-weight: 400;
}

/* Tablet View */
@media (max-width: 768px) {
  .search-home-2 {
    padding: 40px 20px;
  }

  .search-home-2 .searc_home_tab {
    padding: 15px 20px;
  }

  .search-home-2 .searc_home_tab .top_row {
    width: 100%;
    margin-bottom: 1rem;
  }

  .search-home-2 .searc_home_tab .top_row .nav-pills .nav-link {
    font-size: 12px;
  }

  .search-home-2 .bottom_row .select-width,
  .search-home-2 .bottom_row .MuiFormControl-root {
    height: 40px !important;
  }

  .search-home-2 .un-input {
    padding: 1px 80px;
  }

  .bg-white-2 h4 {
    font-size: 26px;
  }

  .bg-white-2 button {
    font-size: 12px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .search-home-2 {
    padding: 30px 5px;
  }

  .search-home-2 .searc_home_tab {
    padding: 10px 15px;
  }

  .search-home-2 .searc_home_tab .top_row {
    width: 100%;
    margin-bottom: 1rem;
  }

  .search-home-2 .searc_home_tab .top_row .nav-pills .nav-link {
    font-size: 11px;
    padding: 0.3rem 0.35rem;
  }

  .search-home-2 .bottom_row .select-width {
    /* height: 35px !important; */
    margin-top: 0.45rem !important;
  }

  .search-home-2 .un-input {
    padding: 1px 0px;
    margin-top: 0px;
    margin-bottom: 0.8rem;
    height: 47px;
  }

  .bg-white-2 h4 {
    font-size: 18px;
  }

  .bg-white-2 button {
    font-size: 11px;
  }
  .bg-white-2 {
    width: 98% !important;
    margin-left: 0% !important;
  }
  .search-home-2 .searc_home_tab .top_row .nav-pills {
    padding: 0.3rem 0.3rem;
    gap: 0.3rem;
    position: unset;
    display: flex;
    flex-direction: row !important;
  }
  .search-home-2 .tab-content {
    padding: 0.3rem 0.3rem;
  }

  .bottom_row .select-width {
    margin: 5px 0rem !important;
  }
  .bottom_row .row > * {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .search-home-2 input,
  .search-home-2 .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
  .search-home-2 .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
  .search-home-2 select .price_edited {
    font-size: 10px !important;
  }

  .search-home-2 .nav-pills .nav-item {
    width: 84px !important;

    padding: 8px !important;
  }

  .search-home-2 .searc_home_tab .top_row .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 7px 13px;
  }

  .search-home-2 .searc_home_tab .top_row .nav-pills .nav-link {
    padding: 7px 13px;
  }
}
