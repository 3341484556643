.wf-form-wrapper {
  background-color: #f0f4f855 !important; /* Light blue background color */
  padding: 10px !important; /* Add some padding */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
}

/* Adding more specificity to the selector */
form .wf-header {
  font-size: 16px !important;
  padding-bottom: 10px !important;
  font-weight: bold;
  word-break: break-word;
}

.wf-field {
  margin-bottom: 15px;
}

.wf-btn {
  background-color: #1980d8;
  color: #fff;
  border: 1px solid #1980d8;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
