.avail-card-num {
  position: absolute;
  top: 3%;
  left: -7%;
  font-size: 3.5rem;
  font-weight: 600;
  font-family: "ClashDisplay";
}

.location_carded {
  width: 15rem;
}

.avail-card-wrap .font_21 {
  font-size: 19px;
  font-weight: 700;
}

.avail-card-wrap p {
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.avail-card-wrap span {
  font-size: 13px;
}

.avail-card-wrap p b {
  font-size: 14px;
}

@media (max-width: 640px) {
  .avail-card-num {
    position: absolute;
    top: 5%;
    left: -7%;
    font-size: 2.1rem;
    font-weight: 600;
  }

  /* .avail-card-wrap .card-img, */
  /* .avail-card-wrap .card-img-bottom,
  .avail-card-wrap .card-img-top {
    width: 15rem;
  } */

  .avail-card-wrap b {
    font-size: 16px;
  }

  .avail-card-wrap span,
  .avail-card-wrap span b {
    font-size: 13px;
  }
  .avail-card-wrap p,
  .avail-card-wrap p b {
    font-size: 13px !important;
  }

  .location_carded {
    width: 12rem;
  }
}
