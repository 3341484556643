@import url("https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Young+Serif&display=swap");

/* font-family: "Instrument Serif", serif;
font-family: "Young Serif", serif; */

.container-fluid.New_Home_Blog {
  padding: 4rem 2.3rem;
  background-color: #121212;
}

.New_Home_Blog .top_item_blog {
  max-width: 650px;
  margin-top: 1.5rem;
}

.New_Home_Blog .row.justify-content-center h2 {
  font-family: "Young Serif", serif !important;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  text-align: center;
  /* padding: 0px 6rem; */
}

.New_Home_Blog .row.justify-content-center p {
  font-family: "Young Serif", serif !important;
  font-size: 0.9rem;
  text-align: center;
  margin: 0.5rem auto 1rem auto;
  color: #929292;
}

.New_Home_Blog .row.justify-content-center .btn {
  display: block;
  margin: 0.4rem auto;
  margin-top: 1rem;
}

.New_Home_Blog .card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0rem;
  padding: 1rem 4.1rem;
  margin-top: 2rem;
}

.New_Home_Blog .card {
  width: 100%;
  max-width: 19rem;
  margin: 1rem 0.3rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.New_Home_Blog .card .card_body {
  background-color: transparent;
  padding: 1rem 0.2rem !important;
  color: #ffffff;
  text-align: left;
}

.New_Home_Blog .card .card_body .card-title span {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.New_Home_Blog .card .card_body .d-flex span {
  font-family: "Young Serif", serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
}

.New_Home_Blog .card .card_body .my-3 {
  border-bottom: none;
}

.New_Home_Blog .card .card_body .card-text,
.New_Home_Blog .card .card_body p {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #929292 !important;
}

@media (max-width: 992px) {
  .New_Home_Blog {
    padding: 3rem 1.5rem;
  }

  .New_Home_Blog .card-container {
    padding: 1rem 2rem;
  }
}

@media (max-width: 768px) {
  .New_Home_Blog {
    padding: 2rem 1rem;
  }

  .New_Home_Blog .top_item_blog {
    margin-top: 1rem;
  }

  .New_Home_Blog .row.justify-content-center h2 {
    font-size: 32px;
  }

  .New_Home_Blog .row.justify-content-center p {
    font-size: 0.8rem;
  }

  .New_Home_Blog .card {
    max-width: 100%;
    margin: 1rem 0.5rem;
  }

  .New_Home_Blog .card-container {
    padding: 1rem;
  }
}

@media (max-width: 576px) {
  .New_Home_Blog {
    padding: 1rem 0.5rem;
  }

  .New_Home_Blog .row.justify-content-center h2 {
    font-size: 28px;
  }

  .New_Home_Blog .row.justify-content-center p {
    font-size: 0.7rem;
  }

  .New_Home_Blog .card {
    margin: 0.5rem 0;
  }

  .New_Home_Blog .card-container {
    padding: 1rem 0.5rem;
  }
}

@media (max-width: 380px) {
  .New_Home_Blog .row.justify-content-center h2 {
    font-size: 23px;
    margin-bottom: 1rem;
  }

  .New_Home_Blog .row.justify-content-center p {
    font-size: 0.8rem;
  }
}
